import { UniSupportApiCS } from '@unikey/unikey-commons/release/csupp'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'dmp';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://dmp-stg-dealer-api.unikey.com/v2/nonces' };
export const supportApi: UniSupportApiCS = new UniSupportApiCS('https://dmp-stg-support-api.unikey.com', 'api/v1', 'DMP', { noNonce: true, enableLogging: true, ...commNonceEndpoint });
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', 'a347554d-bd89-4b2d-9c3a-3e7859227328', '8KEbOTfusrouDoHgIyult+BQx21emats0EcjxGPqxrV/72xU4pl1rCCV9QzP+pLgV21yz4FPT7AP9wsmTjfO4w==', { enableLogging: false, ...commNonceEndpoint });

export const oidcScope: string = 'openid email profile comm.support.api identity.api offline_access';
export const oidcClientId: string = 'a347554d-bd89-4b2d-9c3a-3e7859227328';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://dmp-stg-support.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = '8KEbOTfusrouDoHgIyult+BQx21emats0EcjxGPqxrV/72xU4pl1rCCV9QzP+pLgV21yz4FPT7AP9wsmTjfO4w==';
export const oidcPartnerBrandId: string = 'c0f8a0c6-4394-484a-9826-d6bcb58d28a2';
export const oidcPartnerBrandCode: string = 'DMP';
export const oidcSilentRedirectUri: string = 'https://dmp-stg-support.unikey.com/#/renew';
export const oidcForgotPasswordRedirect: string = '';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://dmp.com/Contact_Us';

export const appInsightsInstrumentationKey: string = 'c4ab9235-20f2-484d-b1ed-d0b2e67bef53';
export const mockAuth: string = '';

export const buildId: string = '36429';
export const releaseId: string = '6208';
export const cspVersion: string = '3.10.0';
export const commonsVersion: string = '8.0.0-pre-1226';

export const desiredLocales: string = 'en,es,ja,fr,pt';
